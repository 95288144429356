.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 10px;
}

.font18 {
  font-size: 18px;
}

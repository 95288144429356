.subtitle {
  font-size: 30px;
  font-weight: 500;
  margin-right: 5px;
}

.colgrey {
  background: #fafafa;
}

.title {
  font-size: 14;
  font-weight: 500;
}

.row {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

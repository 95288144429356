.card {
  height: 30vh;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.emptytext {
  text-align: 'center';
}

@import (reference) 'themes/main-variables.less';

.menudivider {
  display: none;
}

.infotitle {
  color: rgba(255 255 255/ 85%) !important;
  line-height: 4 !important;

  &:hover {
    color: @white-bg !important;
  }
}

.footerbg {
  background-color: #f7f8fa !important;
}

@import (reference) 'themes/main-variables.less';

.filterdropdown {
  padding: 8px;
}

.filterinput {
  display: block !important;
  margin-bottom: 8px !important;
}

.filterbtn {
  width: 90px;
}

.activefilter {
  color: @primary-color !important;
}

@import (reference) 'themes/main-variables.less';

body {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

.ant-layout {
  min-height: 100vh;
}

.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
  background-color: @layout-sider-background;
}

canvas {
  display: block;
}

ul,
ol {
  list-style: none;
}

.yellow-tag {
  border: 1px solid @pending-color;
  color: @pending-color;
  background-color: @pending-light-color;
  font-size: 12px;
  border-radius: 22px;
}

.green-tag {
  border: 1px solid @green;
  color: @green;
  background-color: @green-light-color;
  font-size: 12px;
  border-radius: 22px;
}

.blue-tag {
  border: 1px solid @primary-color;
  color: @primary-color;
  background-color: @primary-light-color;
  font-size: 12px;
  border-radius: 22px;
}

.primarybutton {
  background-color: @primary-color !important;
}

.borderbutton {
  border-color: @primary-color !important;
  color: @primary-color !important;
}

.ant-modal-header {
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
  color: rgb(0 0 0 / 85%);
  padding: 16px 24px;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-footer {
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #f0f0f0;
  padding: 10px 16px;
  text-align: right;
}

.full-width {
  width: 100%;
}

.primarycolor {
  color: @primary-color !important;
}
